<template>
  <b-card>
    <title-text />
    <sub-title-text />
    <content-text />
    <button-text />
  </b-card>
</template>
<script>
import TitleText from '@/views/Admin/Company_profile/elements/TitleText.vue'
import SubTitleText from '@/views/Admin/Company_profile/elements/SubTitleText.vue'
import ContentText from '@/views/Admin/Company_profile/elements/ContentText.vue'
import ButtonText from '@/views/Admin/Company_profile/elements/ButtonText.vue'
import { BCard } from 'bootstrap-vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    SubTitleText,
    ContentText,
    ButtonText,
  },
}
</script>
