<template>
  <b-card title="Dosya Eki">
    <div
      v-if="itemData.filename"
      class="d-flex justify-content-center"
    >
      <div class="rounded width-200 text-center mb-2 p-2 border border-1">
        <div class="border border-1 py-3 rounded">
          <FeatherIcon
            icon="FileIcon"
            size="24"
          />
        </div>
        <b-button
          class="mt-1"
          variant="secondary"
          size="sm"
          block
          :href="baseUrl + '/media/company_profile/' + itemData.filename"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          Görüntüle
        </b-button>
        <b-button
          block
          class="mt-1"
          variant="flat-danger"
          size="sm"
          @click="removeFile"
        >
          <FeatherIcon icon="TrashIcon" />
          Dosyayı Sil
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="text-center mt-1"
    >
      <b-button
        variant="primary"
        @click="setModal"
      >
        <FeatherIcon icon="UploadIcon" />
        Dosya Yükle
      </b-button>
    </div>
    <docs-upload
      v-if="uploadSettings.modalStatus"
      :modal-status="uploadSettings.modalStatus"
      :close-modal="closeModal"
      :folder="uploadSettings.folder"
      component="company_profile"
      :item-id="itemData.id"
      @uploadFile="setUploadFile"
    />
  </b-card>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import DocsUpload from '@/layouts/components/common/DocsUpload.vue'

export default {
  name: 'FileCard',
  components: {
    BCard,
    BButton,
    DocsUpload,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: this.$store.state.app.baseURL,
      uploadSettings: {
        modalStatus: false,
        folder: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['companyProfile/dataItem']
    },
  },
  methods: {
    setModal() {
      this.uploadSettings = {
        modalStatus: true,
        folder: 'company_profile',
      }
    },
    closeModal() {
      this.uploadSettings = {
        modalStatus: false,
        folder: null,
      }
    },
    removeFile() {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('fileUpload/removeData', this.itemData.id_docs)
            .then(response => {
              if (response.status) {
                this.refreshData()
              }
            })
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    setUploadFile(item) {
      this.itemData.filename = item.filename
      this.itemData.filetype = item.filetype
      this.itemData.fileData = item
      this.uploadSettings.modalStatus = false
    },
  },
}
</script>
