<template>
  <validation-observer ref="simpleRules">
    <item-form />
    <b-row class="match-height">
      <b-col
        cols="12"
        md="6"
      >
        <image-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <file-card :refresh-data="getData" />
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol,
  BButton,
} from 'bootstrap-vue'

import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemForm from '@/views/Admin/Company_profile/ItemForm.vue'
import ImageCard from '@/views/Admin/Company_profile/elements/ImageCard.vue'
import FileCard from '@/views/Admin/Company_profile/elements/FileCard.vue'

export default {
  name: 'Index',
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    ItemForm,
    ImageCard,
    FileCard,
  },
  data() {
    return {
      required,
      imageUrl: this.$store.state.app.baseURL,
      itemsForm: false,
      selectItems: null,

    }
  },
  computed: {
    itemData() {
      return this.$store.getters['companyProfile/dataItem']
    },
    saveData() {
      return this.$store.getters['companyProfile/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('companyProfile/getDataItem')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('companyProfile/saveData', this.itemData)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
